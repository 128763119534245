import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { AsistenciaRestcService } from 'src/app/client/asistencia/asistencia-restc.service';

@Injectable({
  providedIn: 'root'
})
export class AsistenciaService extends BaseProcess<AsistenciaRestcService> {

  rest: AsistenciaRestcService;
  
  constructor(httpClient: HttpClient, crest: AsistenciaRestcService) {
    super(httpClient, crest);
    this.rest = crest;
  }

  getWeekOfYear(year: number): Observable<any> {
    return this.rest.getYearWeeks(year);
  }
}
