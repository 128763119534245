import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Asistencia, AsistenciaReg } from 'src/app/api/asistencia/asistencia';
import { Empleado } from 'src/app/api/usuario/empleado';
import { OrganigramaRestcService } from 'src/app/client/organigrama/organigrama-restc.service';
import { AsistenciaService } from 'src/app/procs/asistencia/asistencia.service';

@Component({
  selector: 'axks-asistencia-lider',
  templateUrl: './asistencia-lider.component.html',
  styleUrls: ['./asistencia-lider.component.scss']
})
export class AsistenciaLiderComponent extends BaseMainComponent<AsistenciaService> implements OnInit {

  catEmpleados: Empleado[];

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientAsist: AsistenciaService,
    private clientOrg: OrganigramaRestcService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initCatEmpleados();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idLider: this.profile.idEmpleado
      } as Asistencia,
      minimumItem: {
        idLider: this.profile.idEmpleado
      } as Asistencia,
      registerItem: {
        idLider: this.profile.idEmpleado
      } as AsistenciaReg
    } as ComponentItem;
    return empty;
  }

  getService(): AsistenciaService {
    return this.clientAsist;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colDefs = [
      { field: 'id', header: 'Num. Registro', inAll: true },
      { field: 'idEmpleado', header: 'Num. Empleado', inAll: true },
      { field: 'nombreEmpleado', header: 'Empleado', inAll: true },
      { field: 'fechaAsistencia', header: 'Fecha falta', inAll: true, formatFunction: this.formatoFecha },
      { field: 'fechaAsisLider', header: 'Se registro el', inAll: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return colDefs;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Inasistencias colaboradores';
  }

  formatoFecha(value: any): string {
    return formatDate(value, "dd 'de' MMMM 'de' yyyy", "es-MX", "America/Mexico_City");
  }

  initCatEmpleados() {
    this.clientOrg.empleadosPorLider(this.profile.idEmpleado).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo recuperar la lista de empleados");
      }
    );
  }

  componentsFields(): void {
    super.componentsFields();
    this.formFields = ['empleado', 'fechaFalta'];
    this.formSearch = ['empleado', 'fechaInicio', 'fechaFin'];
  }

}
