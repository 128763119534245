import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Asistencia } from 'src/app/api/asistencia/asistencia';

@Component({
  selector: 'axks-asistencia-empleado-form',
  templateUrl: './asistencia-empleado-form.component.html',
  styleUrls: ['./asistencia-empleado-form.component.scss']
})
export class AsistenciaEmpleadoFormComponent extends BaseView implements OnInit {

  @Input() item: Asistencia;
  yearRange: string;
  currentDate: Date;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    this.initDates();
  }

  initDates() {
    this.currentDate = new Date();
    this.yearRange = (this.currentDate.getFullYear() - 2) + ":" + (this.currentDate.getFullYear() + 2);
  }

}
