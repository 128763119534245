<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaInicio')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecIni" [(ngModel)]="item.fechaIni" dateFormat="yy-mm-dd" [monthNavigator]="true"
                [style]="{'width':'80%'}" dataType="string" [showIcon]="true" [yearNavigator]="true"
                [yearRange]="yearRange"></p-calendar>
            <label for="fl-fecIni">Fecha Inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaFin')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecFin" [(ngModel)]="item.fechaFin" dateFormat="yy-mm-dd" [monthNavigator]="true"
                [style]="{'width':'80%'}" dataType="string" [showIcon]="true" [yearNavigator]="true"
                [yearRange]="yearRange"></p-calendar>
            <label for="fl-fecFin">Fecha Fin</label>
        </span>
    </div>
</div>