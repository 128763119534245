import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AsistenciaEmpleadoComponent } from './views/asistencia/asistencia-empleado/asistencia-empleado/asistencia-empleado.component';

import { AsistenciaLiderComponent } from './views/asistencia/asistencia-lider/asistencia-lider/asistencia-lider.component';
import { AsistenciaRhComponent } from './views/asistencia/asistencia-rh/asistencia-rh/asistencia-rh.component';
import { HomeComponent } from './views/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'asistencia-rh', component: AsistenciaRhComponent },
  { path: 'asistencia-empleado', component: AsistenciaEmpleadoComponent },
  { path: 'asistencia-lider', component: AsistenciaLiderComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
