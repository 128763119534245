import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RestClientModule } from '@axks/net';
import { HttpClientModule } from '@angular/common/http';
import { ActionsBarModule, BaseMessages, CommonsModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeESMX from "@angular/common/locales/es-MX";

import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ContextMenuModule } from 'primeng/contextmenu';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './views/home/home.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AsistenciaEmpleadoFormComponent } from './views/asistencia/asistencia-empleado/asistencia-empleado-form/asistencia-empleado-form.component';
import { AsistenciaRhFormComponent } from './views/asistencia/asistencia-rh/asistencia-rh-form/asistencia-rh-form.component';
import { AsistenciaLiderFormComponent } from './views/asistencia/asistencia-lider/asistencia-lider-form/asistencia-lider-form.component';
import { AsistenciaLiderComponent } from './views/asistencia/asistencia-lider/asistencia-lider/asistencia-lider.component';
import { AsistenciaRhComponent } from './views/asistencia/asistencia-rh/asistencia-rh/asistencia-rh.component';
import { AsistenciaEmpleadoComponent } from './views/asistencia/asistencia-empleado/asistencia-empleado/asistencia-empleado.component';

registerLocaleData(localeESMX, "es-MX");

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AsistenciaLiderFormComponent,
    AsistenciaLiderComponent,
    AsistenciaRhComponent,
    AsistenciaRhFormComponent,
    AsistenciaEmpleadoComponent,
    AsistenciaEmpleadoFormComponent
  ],
  imports: [
    ContextMenuModule,
    InputTextModule,
    MultiSelectModule,
    DialogModule,
    ProgressSpinnerModule,
    BrowserModule,
    CardModule,
    AppRoutingModule,
    RestClientModule,
    HttpClientModule,
    ActionsBarModule,
    CommonsModule,
    DetailTabModule,
    OperationsBarModule,
    ResultListModule,
    FormsModule,
    BrowserAnimationsModule,
    CalendarModule,
    InputTextareaModule,
    CheckboxModule,
    TableModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    CommonModule,
    CommonsModule,
    ScrollPanelModule
  ],
  providers: [MessageService, BaseMessages, SessionClientProvider,
    { provide: 'env', useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
