import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Asistencia, AsistenciaReg } from 'src/app/api/asistencia/asistencia';
import { AsistenciaService } from 'src/app/procs/asistencia/asistencia.service';

@Component({
  selector: 'axks-asistencia-empleado',
  templateUrl: './asistencia-empleado.component.html',
  styleUrls: ['./asistencia-empleado.component.scss']
})
export class AsistenciaEmpleadoComponent extends BaseMainComponent<AsistenciaService> implements OnInit {

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientAsist: AsistenciaService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idEmpleado: this.profile.idEmpleado
      } as Asistencia,
      minimumItem: {
        idEmpleado: this.profile.idEmpleado
      } as Asistencia,
      registerItem: {
        idEmpleado: this.profile.idEmpleado
      } as AsistenciaReg
    } as ComponentItem;
    return empty;
  }

  getService(): AsistenciaService {
    return this.clientAsist;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colDefs = [
      { field: 'id', header: 'Num. Registro', inAll: true },
      { field: 'nombreEmpleado', header: 'Empleado', inAll: true },
      { field: 'fechaAsistencia', header: 'Fecha falta', inAll: true, formatFunction: this.formatoFecha },
      { field: 'nombreLider', header: 'Lider', inAll: true },
      { field: 'fechaAsisLider', header: 'Se registro el', inAll: true, formatFunction: this.formatoFecha },
    ] as ColumnDefinition[];
    return colDefs;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Mis inasistencias';
  }

  formatoFecha(value: any): string {
    return formatDate(value, "dd 'de' MMMM 'de' yyyy", "es-MX", "America/Mexico_City");
  }

}
