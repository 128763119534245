import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Empleado } from 'src/app/api/usuario/empleado';

@Injectable({
  providedIn: 'root'
})
export class OrganigramaRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msOrganigrama;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  empleadosPorLider(idLider: number): Observable<Empleado[]> {
    let path = this.getBaseEndpoint() + "lista-empleados-a-cargo/" + idLider;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<Empleado[]>;
  }
}
