<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<!-- {{item.item | json}} -->
<ng-container *ngIf="loadContent">
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar" [addNewButton]="false" searchLabel="Buscar"
        [searchButton]="false" [sectionTitle]="getComponentTitle()">
    </axks-operations-bar>

    <axks-detail-tab *ngIf="showDetail()" [data]="workItem()" [colsDefinition]="colDefinition"
        [detailFields]="detailFields" class="axks-detail-tab-80">
    </axks-detail-tab>

    <axks-asistencia-rh-form #cmpForm *ngIf="showForm()" [item]="workItem()" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-'+operationMode"
        [catEmpleados]="catEmpleados" [catLideres]="catLideres">
    </axks-asistencia-rh-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" (edit)="edit($event)" (delete)="delete($event)" (csvFile)="csvFile($event)"
        (pdfFile)="pdfFile($event)" (txtFile)="txtFile($event)" saveLabel="Guardar" cancelLabel="Cancelar"
        cleanLabel="Limpiar" [printButton]="false" [csvFileButton]="false" [pdfFileButton]="false"
        [txtFileButton]="false" [deleteButton]="false" [editButton]="false">
    </axks-actions-bar>

    <axks-result-list *ngIf="showResultList()" [data]="resultList" [colsDefinition]="colDefinition"
        (rowSelect)="rowSelect($event)" (rowUnselect)="rowUnselect($event)" [listFields]="listFields">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>