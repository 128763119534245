<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('empleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idEmpleado" [options]="catEmpleados"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false" autoWidth="false"
                [style]="{'width':'80%'}" [filter]="true"></p-dropdown>
            <label for="fl-tcnm">Empleado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('empleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idLider" [options]="catLideres"
                optionLabel="nombre" optionValue="idEmpleado" [autoDisplayFirst]="false" autoWidth="false"
                [style]="{'width':'80%'}" [filter]="true"></p-dropdown>
            <label for="fl-tcnm">Lider</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('fechaFalta')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fec" [(ngModel)]="item.fechaAsistencia" dateFormat="yy-mm-dd" [monthNavigator]="true"
                [yearNavigator]="true" [style]="{'width':'80%'}" dataType="string" [showIcon]="true"
                [yearRange]="yearRange" [maxDate]="currentDate"></p-calendar>
            <label for="fl-fec">Fecha</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('fechaInicio')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecIni" [(ngModel)]="item.fechaIni" dateFormat="yy-mm-dd" [monthNavigator]="true"
                [style]="{'width':'80%'}" dataType="string" [showIcon]="true" [yearNavigator]="true"
                [yearRange]="yearRange"></p-calendar>
            <label for="fl-fecIni">Fecha Inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('fechaFin')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecFin" [(ngModel)]="item.fechaFin" dateFormat="yy-mm-dd" [monthNavigator]="true"
                [style]="{'width':'80%'}" dataType="string" [showIcon]="true" [yearNavigator]="true"
                [yearRange]="yearRange"></p-calendar>
            <label for="fl-fecFin">Fecha Fin</label>
        </span>
    </div>
</div>