import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Asistencia, AsistenciaReg } from 'src/app/api/asistencia/asistencia';
import { EmpleadoNombre, Lider } from 'src/app/api/asistencia/empleado';
import { AsistenciaService } from 'src/app/procs/asistencia/asistencia.service';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';

@Component({
  selector: 'axks-asistencia-rh',
  templateUrl: './asistencia-rh.component.html',
  styleUrls: ['./asistencia-rh.component.scss']
})
export class AsistenciaRhComponent extends BaseMainComponent<AsistenciaService> implements OnInit {

  catEmpleados: EmpleadoNombre[];
  catLideres: Lider[];

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientAsist: AsistenciaService,
    private clientEmp: EmpleadoPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initCatEmpleados();
    this.initCatLideres();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as Asistencia,
      minimumItem: {} as Asistencia,
      registerItem: {} as AsistenciaReg
    } as ComponentItem;
    return empty;
  }

  getService(): AsistenciaService {
    return this.clientAsist;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colDefs = [
      { field: 'id', header: 'Num. Registro', inAll: true },
      { field: 'idEmpleado', header: 'Num. Empleado', inAll: true },
      { field: 'nombreEmpleado', header: 'Empleado', inAll: true },
      { field: 'fechaAsistencia', header: 'Fecha falta', inAll: true, formatFunction: this.formatoFecha },
      { field: 'nombreLider', header: 'Lider', inAll: true },
      { field: 'fechaAsisLider', header: 'Se registro el', inAll: true, formatFunction: this.formatoFecha },

    ] as ColumnDefinition[];
    return colDefs;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Inasistencias de empleados';
  }

  formatoFecha(value: any): string {
    return formatDate(value, "dd 'de' MMMM 'de' yyyy", "es-MX", "America/Mexico_City");
  }

  initCatEmpleados() {
    this.clientEmp.empleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo recuperar la lista de empleados");
      }
    );
  }

  initCatLideres() {
    this.clientEmp.listaLideres(this.profile.empresa).subscribe(
      (data) => {
        this.catLideres = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo recuperar la lista de lideres");
      }
    );
  }

  componentsFields(): void {
    super.componentsFields();
    this.formFields = ['empleado', 'fechaFalta'];
    this.formSearch = ['empleado', 'fechaInicio', 'fechaFin'];
  }

}
