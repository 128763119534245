import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AppClient, SessionClientProvider } from '@axks/components';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'axks-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent extends AppClient {
  title = 'asistencia-gui';

  constructor(
    protected router: Router,
    protected messageService: MessageService,
    protected sessionProv: SessionClientProvider,
    @Inject(DOCUMENT) private docs: Document) {
    super(environment, router, messageService, sessionProv, docs);

  }
}
