import { version } from '../../package.json';

export const environment = {
  production: false,
  dev: false,
  uat: true,
  version: version,

  appId: "asistencia-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  msEmpleado: 'https://rh.uat-ms.axkans.org/rh/empleado/ps',
  msPersona: 'https://pers.uat-ms.axkans.org/pers/person',
  msAsistencia: 'https://rh.uat-ms.axkans.org/rh/asistencia',
  msListaEmpleadoRH: 'https://rh.uat-ms.axkans.org/rh/empleado/all',
  msOrganigrama: 'https://rh.uat-ms.axkans.org/rh/organigrama',
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
};
