import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmpleadoNombre, Lider } from 'src/app/api/asistencia/empleado';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoPsService extends BaseProcess<EmpleadoRestcService>{

  client: EmpleadoRestcService;

  constructor(httpClient: HttpClient, crest: EmpleadoRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  empleadosActivos(idEmpresa: number): Observable<EmpleadoNombre[]> {
    return this.client.empleadosActivos(idEmpresa);
  }

  listaLideres(idEmpresa: number): Observable<Lider[]> {
    return this.client.listaLideres(idEmpresa);
  }
}
